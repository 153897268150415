import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { parseUrl, stringifyQueryParams } from 'url-fns';

import { cleanseDynamicRouteUrl } from './removeDynamicRoutePathParamsFromUrlQueryParams';

export const useNextRouterQueryState = <
  T extends Record<string, string | undefined>,
>() => {
  const router = useRouter();

  // parse the path, grab query state from it
  const { path, queryParams, hash } = parseUrl(
    cleanseDynamicRouteUrl(router.asPath),
  );
  const queryState = queryParams as T;

  // define method to set query state, w/o updating path
  const setQueryState = useCallback(
    (updatedQueryState: T) =>
      router.push(
        {
          pathname: path, // split and join because only `asPath` has the dynamic url part in it, but it also has the query
          query: stringifyQueryParams(updatedQueryState),
          hash,
        },
        undefined,
        { shallow: true }, // shallow -> dont hit serverside when query strings change; code smell if we would have needed to
      ),
    [router, path, hash],
  );

  // define method to update the query state, w/o updating path
  const updateQueryState = useCallback(
    (partiallyUpdatedQueryState: Partial<T>) =>
      setQueryState({ ...queryState, ...partiallyUpdatedQueryState }),
    [queryState, setQueryState],
  );

  /**
   * if the router is not ready yet, then return nothing
   * - this is because the query params are not available in this context yet
   * - this only occurs for `static` pages compiled by nextjs ahead of time
   *   - specifically, static server-side-rendered pages have router _not_ ready on the server-side _and_ for the initial render on the client-side
   *   - in contrast, dynamic server-side-rendered pages have router.isReady and have access to the query parameters, even when server side rendering
   *
   * this is important, as if this is not done - the html rendered by the server and hydrated on client side will be _really_ badly malformed
   */
  if (!router.isReady) {
    return {
      queryState: {} as unknown as T,
      setQueryState: () => {
        throw new Error(
          'useNextRouterQueryState.setQueryState can not be called before router is ready',
        );
      },
      updateQueryState: () => {
        throw new Error(
          'useNextRouterQueryState.updateQueryState can not be called before router is ready',
        );
      },
    };
  }

  // return the state and set query
  return { queryState, setQueryState, updateQueryState };
};
