import { phone } from 'phone';

import { UnexpectedCodePathError } from '../../utils/errors/UnexpectedCodePathError';

/**
 * normalizes phone number into E.164 format
 * - E.164 example: +18175698900
 */
export const normalizePhoneNumber = (phoneNumber: string) => {
  const { phoneNumber: normalizedPhoneNumber } = phone(phoneNumber);
  if (!normalizedPhoneNumber)
    throw new UnexpectedCodePathError(
      'invalid phone number. should have made sure to check if its valid first',
      { phoneNumber },
    );
  return normalizedPhoneNumber;
};
