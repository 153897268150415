import { Text, TextType } from '../generic/Text';

export const SSLEncryptedSecurityBadge = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center ',
      }}
    >
      <Text type={TextType.DETAILS}>🔒 256-bit SSL Encrypted Security</Text>
    </div>
  );
};
