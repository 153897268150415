import queryString from 'query-string';
import { isPresent } from 'simple-type-guards';

const escapeRegexp = (string: string) => {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

/**
 * removes the dynamic-route-path-params from the query-parameters section of a url
 *
 * next.js likes to give us `urls` that include the `dynamic-route-path-params` in both the `path` and the `query-params` sections of the urls:
 * - e.g.,:
 *   - page defined as: `/jobs/[job-slug]`
 *   - expected display: `jobs/some-title-${identifier}`
 *   - returned req.url: `jobs/some-title-${identifier}?job-slug=some-title-${identifier}`
 *
 * that functionality is not helpful for us when we're trying to redirect the user based on the previous url, as the `req.url` is messy
 *
 * this function takes the messy url and cleans it.
 *
 * example:
 * ```ts
 * // no query params besides the dynamic-route-path-parameters
 * const cleanUrl = removeDynamicRoutePathParamsFromUrlQueryParams(`jobs/some-title-${identifier}?job-slug=some-title-${identifier}`);
 * console.log(cleanUrl); // `jobs/some-title-${identifier}`
 *
 * // other query parameters along with the dynamic-route-path-parameters
 * const cleanUrl = removeDynamicRoutePathParamsFromUrlQueryParams(`jobs/some-title-${identifier}?job-slug=some-title-${identifier}&focus=true`);
 * console.log(cleanUrl); // `jobs/some-title-${identifier}?focus=true`
 * ```
 *
 * under the hood:
 * - determines which query params are "dynamic-route-path-parameters" by checking whether the value already exists in the path. if it does, its a dynamic path param
 *
 * references:
 * - https://nextjs.org/docs/routing/dynamic-routes
 */
export const removeDynamicRoutePathParamsFromUrlQueryParams = (url: string) => {
  const [path, queryParamsString] = url.split('?');
  const queryParams = queryString.parse(queryParamsString);
  const dynamicRoutePathParamKeys = Object.keys(queryParams).filter((key) => {
    if (typeof queryParams[key] !== 'string') return false;
    return path.match(
      new RegExp(`\/${escapeRegexp(queryParams[key] as string)}(\/|$)`),
    );
  });
  const queryParamsWithoutDynamicRoutePathParams = Object.keys(
    queryParams,
  ).reduce<{ [index: string]: string | string[] | null }>(
    (summary, thisKey) => {
      if (dynamicRoutePathParamKeys.includes(thisKey)) return summary;
      return { ...summary, [thisKey]: queryParams[thisKey] };
    },
    {},
  );
  const hasQueryParamsBesidesDynamicRoutePathParams =
    Object.keys(queryParamsWithoutDynamicRoutePathParams).length > 0;
  const urlExcludingDynamicRoutePathParams = [
    path,
    hasQueryParamsBesidesDynamicRoutePathParams
      ? queryString.stringify(queryParamsWithoutDynamicRoutePathParams)
      : null,
  ]
    .filter(isPresent)
    .join('?');
  return urlExcludingDynamicRoutePathParams;
};

/**
 * an alias for removeDynamicRoutePathParamsFromUrlQueryParams
 */
export const cleanseDynamicRouteUrl =
  removeDynamicRoutePathParamsFromUrlQueryParams;
