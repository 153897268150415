import { useState } from 'react';
import useIsMounted from 'react-is-mounted-hook';

/**
 * takes an async function, wraps that function so that we expose a state object defining whether the function is currently loading or not (i.e., pending promise or completed/not-started)
 */
export const useLoadingState = <T extends (...args: any[]) => Promise<any>>(
  logic: T,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const checkIsMounted = useIsMounted();
  const wrappedLogic = async (...args: Parameters<T>) => {
    try {
      setIsLoading(true);
      return await logic(...args);
    } finally {
      if (checkIsMounted()) setIsLoading(false);
    }
  };
  return [isLoading, wrappedLogic] as [boolean, T];
};
