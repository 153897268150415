import { css, Global } from '@emotion/react';
import React from 'react';

import { colors } from '../../../constants/colors';
import { AppPageMaxWidth } from '../../page-agnostic/layout/AppPageMaxWidth';
import { SpacerHeight } from '../../page-agnostic/layout/SpacerHeight';
import { LoginUI } from './LoginUI';

export const LandingPage = () => {
  return (
    <>
      <Global
        styles={css`
          html,
          body {
            /* branded background color */
            background-color: ${colors.accent.main};
          }
        `}
      />
      <AppPageMaxWidth>
        <LoginUI />
        <SpacerHeight size={4} />
      </AppPageMaxWidth>
    </>
  );
};
