import React, {
  useRef,
  ReactChild,
  MouseEventHandler,
  FocusEventHandler,
  Ref,
} from 'react';

import { colors } from '../../../constants/colors';
import { fonts } from '../../../constants/fonts';
import { SpacerWidth } from '../layout/SpacerWidth';

export enum ActionTextInputSize {
  STANDARD = 'STANDARD',
  LARGE = 'LARGE',
}

export const ActionTextInput = ({
  onClick,
  onChange,
  onSubmit,
  onBlur,
  onFocus,
  onKeyDown,
  placeholder,
  inputMode,
  inputRef: inputElementRefFromProps,
  rootRef: rootElementRefFromProps,
  prefix,
  autoFocus,
  value,
  multiline,
  size = ActionTextInputSize.LARGE,
  readOnly,
  isPassword = false,
}: {
  onClick?: MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
  ) => void;
  onBlur?: FocusEventHandler<HTMLInputElement & HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement & HTMLTextAreaElement>;
  onSubmit?: () => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement & HTMLTextAreaElement>,
  ) => void;
  placeholder: string;
  inputMode?: 'numeric' | 'email' | 'tel' | 'search' | 'url' | 'text';
  prefix?: ReactChild;
  inputRef?: React.MutableRefObject<HTMLElement | null>; // e.g., const inputElementRef = useRef<HTMLInputElement | null>(null);
  rootRef?: Ref<any>;
  autoFocus?: boolean;
  value?: string;
  multiline?: boolean;
  size?: ActionTextInputSize;
  readOnly?: boolean;
  isPassword?: boolean;
}) => {
  // define a way to programmatically focus the input
  const inputElementRef = useRef<HTMLElement | null>(null); // null since https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572#issuecomment-498242139
  const focusInput = () => inputElementRef.current?.focus();

  // define the onKeyUp handler
  const onKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === 13 && !multiline && onSubmit) onSubmit(); // if enter was pressed, call on submit
  };

  // render the input
  return (
    <div
      css={{
        // used to contain the padding
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
      }}
    >
      <div
        onClick={(onClick as any) ?? focusInput}
        ref={rootElementRefFromProps}
        css={{
          padding:
            size === ActionTextInputSize.LARGE ? '20px 15px' : '10px 7px',
          borderRadius: '3px',
          backgroundColor: 'white',
          flex: '1',
          display: 'flex',
          cursor: 'text',
          alignItems: 'center',

          // toggle color of border and box-shadow on focus
          border: `1px solid ${colors.accent.grayOutline}`,
          '&:focus-within': {
            border: '1px solid #F17186',
            // boxShadow: '0px 0px 3px 1px rgba(63,81,181,0.58)',
            boxShadow:
              'rgba(63,81,181,0.02) 0px 3px 3px -2px, rgba(63,81,181,0.14) 0px 3px 4px 0px, rgba(63,81,181,0.12) 0px 1px 8px 0px;',
          },
        }}
      >
        {prefix ? (
          <>
            {prefix}
            <SpacerWidth size={2} />
          </>
        ) : null}
        {
          // TODO: think through how to dedupe the props to these components well
          multiline ? (
            <textarea
              onClick={onClick ?? ((event) => event.stopPropagation())} // stopPropagation since we dont want to "focus" again on the parent.onclick, its already focused
              onChange={onChange}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              ref={(ref: HTMLElement | null) => {
                inputElementRef.current = ref;
                if (inputElementRefFromProps)
                  inputElementRefFromProps.current = ref; // eslint-disable-line no-param-reassign
              }}
              css={{
                flex: '1',
                fontSize: size === ActionTextInputSize.LARGE ? '18px' : '15px',
                border: 'none',
                fontFamily: fonts.static,
              }}
              readOnly={readOnly}
              value={value ?? undefined}
              placeholder={placeholder}
              inputMode={inputMode}
              autoFocus={autoFocus} // NOTE! autoFocus does not work in nextjs:dev deployment for some reason! try in prod if you're seeing this problem
            />
          ) : (
            <input
              onClick={onClick ?? ((event) => event.stopPropagation())} // stopPropagation since we dont want to "focus" again on the parent.onclick, its already focused
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              ref={(ref: HTMLInputElement | null) => {
                inputElementRef.current = ref;
                if (inputElementRefFromProps)
                  inputElementRefFromProps.current = ref; // eslint-disable-line no-param-reassign
              }}
              css={{
                flex: '1',
                fontSize: size === ActionTextInputSize.LARGE ? '18px' : '15px',
                border: 'none',
                fontFamily: fonts.static,
              }}
              readOnly={readOnly}
              value={value ?? undefined}
              placeholder={placeholder}
              inputMode={inputMode}
              autoFocus={autoFocus} // NOTE! autoFocus does not work in nextjs:dev deployment for some reason! try in prod if you're seeing this problem
              type={isPassword ? 'password' : undefined}
            />
          )
        }
      </div>
    </div>
  );
};
