import { AuctionHouseAgentRolePermission } from './AuctionHouseAgentRole';

export enum AuthRoleDesignatorType {
  AUCTION_HOUSE = 'AUCTION_HOUSE',
  SHIPMENT_FACILITATOR = 'SHIPMENT_FACILITATOR',
}

export enum ShipmentFacilitatorAgentRolePermission {
  DATA_SET_SHIPMENT_ALL = 'data:set:shipment:all',
  DATA_GET_SHIPMENT_ALL = 'data:get:shipment:all',
}

/**
 * the assignment of a role to a person (the designee) by some group (the designator)
 *
 * note
 * - this "App*" prefix indicates that this dobj has hydrated data for convinient use in the frontend (e.g., the role)
 */
export interface AuthRoleDesignation {
  /**
   * the type of group that assigned this designation
   *
   * for example
   * - AUCTION_HOUSE
   * - SHIPMENT_FACILITATOR
   *
   * usecase
   * - enables determining what tables to lookup the designator, role, and designee in
   */
  designatorType: AuthRoleDesignatorType;

  /**
   * the exact identifier of the group that assigned this designation
   *
   * for example
   * - auctionHouse.uuid
   * - ShipmentFacilitator.uuid
   */
  designatorUuid: string;

  /**
   * the user uuid that was assigned this designation
   *
   * a.k.a. designeeUuid
   */
  whodisUserUuid: string;

  /**
   * the exact identifier of the role that was designated
   *
   * note
   * - the table in which this uuid references a role depends on the designatorType
   */
  roleUuid: string;

  /**
   * the role specified by the roleUuid, hydrated for convenient use in the frontend
   */
  role: {
    uuid: string;
    name: string;
    permissions: (
      | ShipmentFacilitatorAgentRolePermission
      | AuctionHouseAgentRolePermission
    )[];
  };
}
