import React from 'react';
import Spinner from 'react-spinner-material';

import { colors } from '../../../constants/colors';

export const LoadingSpinner = ({
  size = 21,
  thickness = 3,
  color = colors.accent.main,
}: {
  size?: number;
  thickness?: number;
  color?: string;
}) => <Spinner radius={size} color={color} stroke={thickness} visible={true} />;
