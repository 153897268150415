import { Card, Divider } from '@mui/material';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  ChallengeGoal,
  ContactMethodType,
  isWhodisBadRequestError,
  useConfirmationCodeChallenge,
} from 'whodis-react';
import { storage } from 'whodis-react-storage-browser';

import { useNextRouterQueryState } from '../../../__nonpublished_modules__/nextjs-utils/src/useNextRouterQueryState';
import { colors } from '../../../constants/colors';
import { normalizePhoneNumber } from '../../../data/normalization/normalizePhoneNumber';
import { isValidPhoneNumber } from '../../../data/validation/isValidPhoneNumber';
import { AuthRoleDesignatorType } from '../../../domain/objects/AuthRoleDesignation';
import { useAuth } from '../../../hooks/useAuth';
import {
  getBackupLoginGroupFromPassword,
  useAuthedBackupLoginGroup,
} from '../../../hooks/useAuthedBackupLoginGroup';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { log } from '../../../utils/log';
import { sleep } from '../../../utils/sleep';
import { SSLEncryptedSecurityBadge } from '../../page-agnostic/branding/SSLEncryptedSecurityBadge';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '../../page-agnostic/generic/ActionButton';
import { ActionTextInput } from '../../page-agnostic/generic/ActionTextInput';
import { LoadingSpinner } from '../../page-agnostic/generic/LoadingSpinner';
import { Text, TextType } from '../../page-agnostic/generic/Text';
import { AppPageMaxWidth } from '../../page-agnostic/layout/AppPageMaxWidth';
import { SpacerHeight } from '../../page-agnostic/layout/SpacerHeight';

export const LoginUI = () => {
  const router = useRouter();

  const { queryState } = useNextRouterQueryState<{ for: string }>();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const [usePhoneNumberLogin, setUsePhoneNumberLogin] = useState<boolean>(true);
  // determine if its for a public viewer or not
  const {
    isLoggedIn,
    authedWhodisUserUuid,
    authedBackupLoginGroup,
    designations,
    role,
  } = useAuth();

  const hasDesignations = !!designations;
  const hasAuctionHouseDesignation = designations?.some(
    (designation) =>
      designation.designatorType === AuthRoleDesignatorType.AUCTION_HOUSE,
  );
  const agentHasLimitedCustomerAccess = role?.permissions.some((permission) =>
    permission.startsWith('data:customers:get:all:limited'),
  );

  useEffect(() => {
    // if they're actually already logged in, just redirect them immediately; sometimes serverside will say they're not logged in if a third party had sent them
    if (isLoggedIn) {
      // if they requested to go somewhere already, send them there
      if (queryState.for) {
        setIsRedirecting(true);
        log.info('redirecting', { for: queryState.for });
        void router.push(queryState.for);
      }

      // otherwise, redirect them to best guess destination
      if (hasDesignations)
        void (async () => {
          if (agentHasLimitedCustomerAccess)
            return await router.push('/customers'); // if they're a shipper, they only have customer access, so redirect them to the customer page
          if (hasAuctionHouseDesignation) return await router.push('/auctions'); // once this submitted successfully, redirect them to the dashboard
          return await router.push('/shipments'); // otherwise, default to going to shipments page
        })();
    }
  }, [
    router,
    isLoggedIn,
    authedBackupLoginGroup,
    authedWhodisUserUuid,
    queryState.for,
    hasAuctionHouseDesignation,
    hasDesignations,
    agentHasLimitedCustomerAccess,
  ]);

  const {
    challengeHasBeenAsked,
    askConfirmationCodeChallenge,
    answerConfirmationCodeChallenge,
  } = useConfirmationCodeChallenge({ storage });
  const [pendingPhoneNumberInput, setPendingPhoneNumberInput] = useState('');
  const [pendingAnswer, setPendingAnswer] = useState('');

  const onSubmitPhoneNumberInput = async () => {
    if (
      !pendingPhoneNumberInput ||
      !isValidPhoneNumber(pendingPhoneNumberInput)
    )
      return window.alert('please enter a valid phone number'); // eslint-disable-line no-alert
    const phoneNumberToAuthWith = normalizePhoneNumber(pendingPhoneNumberInput);
    try {
      return await askConfirmationCodeChallenge({
        goal: ChallengeGoal.LOGIN,
        contactMethod: {
          type: ContactMethodType.PHONE,
          address: phoneNumberToAuthWith,
        },
      });
    } catch (error) {
      alert(
        'This phone number is not authorized to login. If you think you should be able to login, please contact your administrator.',
      );
      // !: don't throw an error in the future, because we don't want to give away whether a phone number is in use already
    }
  };

  const [isLoadingSubmitInputFromEnterKey, onPressOfSubmitInputFromEnterKey] =
    useLoadingState(async () => {
      await onSubmitPhoneNumberInput();
    });

  // 3176994528
  const onSubmitConfirmationCode = async (): Promise<void> => {
    if (!pendingAnswer || pendingAnswer.length < 5)
      return window.alert('please enter a valid confirmation code'); // eslint-disable-line no-alert
    try {
      await answerConfirmationCodeChallenge({ answer: pendingAnswer });
      await sleep(5000); // wait up to 5 seconds for the ui to redirect us via useEffect
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // if the user got the answer wrong, tell them
      if (
        isWhodisBadRequestError(error) &&
        error.message.includes('challenge was not answered correctly')
      ) {
        return window.alert(
          'that confirmation code was not correct. try again?',
        ); // eslint-disable-line no-alert
      }

      // otherwise, report the error like normal
      throw error;
    }
  };
  const [isLoadingSubmitAnswerFromEnterKey, onPressOfSubmitAnswerFromEnterKey] =
    useLoadingState(async () => {
      await onSubmitConfirmationCode();
    });

  // define what to do on resend confirmation code
  const [isResendingConfirmationCode, onResendConfirmationCode] =
    useLoadingState(async () => {
      // trigger sending again
      await onSubmitPhoneNumberInput();
    });

  const { logIntoBackupLoginGroupWithPassword } = useAuthedBackupLoginGroup();
  const [passwordInput, setPasswordInput] = useState('');
  const onSubmitPassword = async () => {
    if (getBackupLoginGroupFromPassword({ password: passwordInput }) === null) {
      alert('password was invalid');
      return;
    }
    logIntoBackupLoginGroupWithPassword({ password: passwordInput });
    await router.push('/auctions');
  };

  const [
    isLoadingSubmitPasswordFromEnterKey,
    onPressOfSubmitPasswordFromEnterKey,
  ] = useLoadingState(async () => {
    await onSubmitPassword();
  });

  const askForInput = usePhoneNumberLogin ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text type={TextType.TITLE}>What is your phone number?</Text>
      <Text type={TextType.DETAILS}>
        What is the phone number associated with your account?
      </Text>
      <SpacerHeight size={2} />
      <ActionTextInput
        inputMode="tel"
        placeholder="phone number"
        onChange={(event) => setPendingPhoneNumberInput(event.target.value)}
        onSubmit={onPressOfSubmitInputFromEnterKey}
        value={pendingPhoneNumberInput}
        autoFocus
      />
      <SpacerHeight size={1} />
      <SSLEncryptedSecurityBadge />
      <SpacerHeight size={2} />
      <ActionButton
        variant={ActionButtonVariant.CALL_TO_ACTION}
        size={ActionButtonSize.LARGE}
        fillContainer
        action={{ onClick: onSubmitPhoneNumberInput }}
        disabled={isLoadingSubmitInputFromEnterKey}
      >
        Login
      </ActionButton>
      <SpacerHeight size={1} />
      <Text type={TextType.DETAILS}>
        By pressing the button above you allow Aether Auctions to send you a
        one-time confirmation code.
      </Text>
      <SpacerHeight size={1} />
      <Divider>or</Divider>
      <SpacerHeight size={1} />
      <ActionButton
        variant={ActionButtonVariant.TEXT}
        fillContainer
        size={ActionButtonSize.STANDARD}
        action={{ onClick: () => setUsePhoneNumberLogin(false) }}
      >
        Log in with Password
      </ActionButton>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text type={TextType.TITLE}>What is your password?</Text>
      <Text type={TextType.DETAILS}>
        What is the password associated with your account?
      </Text>
      <SpacerHeight size={2} />
      <ActionTextInput
        inputMode="text"
        placeholder="password"
        value={passwordInput}
        onChange={(e) => setPasswordInput(e.target.value)}
        onSubmit={onPressOfSubmitPasswordFromEnterKey}
        autoFocus
        isPassword={true}
      />
      <SpacerHeight size={1} />
      <SSLEncryptedSecurityBadge />
      <SpacerHeight size={2} />
      <ActionButton
        variant={ActionButtonVariant.CALL_TO_ACTION}
        fillContainer
        size={ActionButtonSize.LARGE}
        action={{ onClick: onSubmitPassword }}
        disabled={isLoadingSubmitPasswordFromEnterKey}
      >
        <span style={{}}>Login</span>
      </ActionButton>

      <SpacerHeight size={1} />
      <Divider>or</Divider>
      <SpacerHeight size={1} />
      <ActionButton
        variant={ActionButtonVariant.TEXT}
        fillContainer
        size={ActionButtonSize.STANDARD}
        action={{ onClick: () => setUsePhoneNumberLogin(true) }}
      >
        Log in with Phone
      </ActionButton>
    </div>
  );
  const askForConfirmationCode = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text type={TextType.TITLE}>What is the confirmation code?</Text>
      <Text type={TextType.DETAILS}>
        A confirmation code has been sent to {pendingPhoneNumberInput}. What was
        it?
      </Text>

      <SpacerHeight size={2} />
      <ActionTextInput
        inputMode="numeric"
        placeholder="your confirmation code"
        onChange={(event) => setPendingAnswer(event.target.value)}
        onSubmit={onPressOfSubmitAnswerFromEnterKey}
        value={pendingAnswer}
        autoFocus
      />

      <SpacerHeight size={2} />
      <ActionButton
        variant={ActionButtonVariant.ACTION_ELEVATION}
        size={ActionButtonSize.LARGE}
        action={{ onClick: onSubmitConfirmationCode }}
        disabled={isLoadingSubmitAnswerFromEnterKey}
      >
        Confirm
      </ActionButton>
      <SpacerHeight size={1.5} />
      {isResendingConfirmationCode ? (
        <>
          <Text type={TextType.DETAILS}>
            Resending the code now
            <div
              style={{
                display: 'inline-block',
                paddingLeft: 10,
              }}
            >
              <LoadingSpinner
                size={10}
                thickness={1}
                color={colors.text.lightBackground.secondary}
              />
            </div>
          </Text>
        </>
      ) : (
        <>
          <Text type={TextType.DETAILS}>
            Did not receive the code?{' '}
            <Text type={TextType.DETAILS} onClick={onResendConfirmationCode}>
              Click here to resend.
            </Text>
          </Text>
        </>
      )}
      <SpacerHeight size={2} />
    </div>
  );
  const loginFlowContentToDisplay = challengeHasBeenAsked
    ? askForConfirmationCode
    : askForInput;

  return (
    <>
      <Head>
        <title>Login | Aether Auctions</title>
      </Head>
      <AppPageMaxWidth>
        {/* <HeaderInApp leftAlign={HeaderInAppLeftAlign.TO_PAGE_SECTION} /> */}
        {isRedirecting ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SpacerHeight size={3} />
            <LoadingSpinner size={42} />
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <SpacerHeight size={4} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  src="https://i.imgur.com/p1EyrWn.png"
                  style={{ width: 150 }}
                  alt="logo"
                />
              </div>
              <SpacerHeight size={4} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Text type={TextType.HEADLINE}>
                  <span style={{ color: 'white', fontSize: 45 }}>
                    Aether Auctions
                  </span>
                </Text>
              </div>
              <SpacerHeight size={4} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Card
                style={{
                  width: '95%',
                  maxWidth: 600,
                  padding: 32,
                }}
              >
                {loginFlowContentToDisplay}
              </Card>
            </div>
          </>
        )}
      </AppPageMaxWidth>
    </>
  );
};
